.principles h3 {
  color: #c90e3a;
}

.creed {
  background: #c90e3a;
  color: white;
  margin: 30px 0;
  padding: 20px;
}
