.footer {
  background-color: #ababab;
  text-align: center;
  padding: 50px 0 0;
  margin-top: 50px;
  font-family: 'GreyCliffCF'
}

.social span {
  color: #c90e3a;
}

.social span:hover {
  color: #fff;
}

.social {
  margin-bottom: 50px;
  width: 80%;
}

.contact {
  background-color: #d5d5d5;
  padding: 20px;
}

.contact span {
  margin-bottom: 5px;
}

.contact a {
  color: #c90e3a;
}

.contact a:hover {
  color: #960a2b;
  text-decoration: none;
}

.footerFoot {
  color: #fff;
  background-color: #c90e3a;
  text-align: center;
  padding: 20px;
}

.footerFoot a {
  color: #fff;
}

.footerFoot a:hover {
  color: #ebebeb;
  text-decoration: none;
}

.footer img {
  height: 100px;
  margin: 20px 0;
}

