.award {
  background: #fff;
  padding: 30px 10px;
  font-family: 'GreyCliffCF';
}

.award.alt {
  background: #c90e3a;
  color: #fff;
}

.award h1, h2 {
  text-transform: uppercase;
}

.award h1 {
  font-weight: bold;
}

.awardCol {
  padding: 10px;
}

.letters {
  width: 100%;
}
